body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header-brand-img {
  height: 9rem !important;
}

/*Leaflet Popup styles*/
.popupContent {
  textAlign: center;
  height: auto;
  marginTop: 10px;
}
.popupHead {
  fontWeight: bold;
  fontSize: 15px;
}
.popupText {
  textAlign: left;
  fontSize: 12px;
  marginBottom: 10px;
}

.nodecorate:hover, .nodecorate:active, .nodecorate:link, .nodecorate:visited {
	text-decoration: none;
  color: #495057 !important;
}

.explore_overlay {
  display: block;
  position: absolute;
  padding: 10px;
  text-align: center;
  color: white;
  text-shadow: 5px 5px 10px black;
  bottom: 0;
  width: 100%
}

.react-dual-listbox {
  height: 480px;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 950px;
  overflow: auto;
}

.highlightCalendar {
  color: white;
  background: #f88379 !important;
}

/*weekends font to match style of weekdays*/
.react-calendar__month-view__days__day--weekend{
  color: black !important;
}

.react-calendar__month-view__days__day--weekend.highlightCalendar{
  color: white !important;
}

.react-calendar__month-view__days__day--weekend.react-calendar__month-view__days__day--neighboringMonth{
  color: gray !important;
}

.leaflet-container {
	height: 700px;
	width: 100%;
	margin: 0 auto;
}

.leaflet_advisory_map {
  display: block;
  height: 610px;
  width: 100%;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 18px;
  margin-right: 14px;
}

.leaflet_monitoring_map{
  height: 770px;
  width: 100%;
  margin: 0 auto;
}

.ReactModal__Overlay {
	z-index: 1000;
}
